<template>
     <div>
        <a-card style="margin-bottom:20px">
            <a-form layout="inline">
                <a-form-item label="标签组">
                    <a-select style="width:200px" v-model="searchForm.group_name">
                        <a-select-option v-for="item in groupList" :key = "item.group_name" :value="item.group_name">
                            {{item.group_name}}
                        </a-select-option>
                    </a-select>
                </a-form-item>
                <a-form-item >
                <a-button type="primary"
                            icon="search"
                            @click="handleClickSearch">
                    搜索
                </a-button>
                </a-form-item>
                <a-form-item >
                <a-button 
                            icon="reload"
                            @click="resetSearchForm">
                    重置
                </a-button>
                </a-form-item>
            </a-form>
        </a-card>
        <a-card>
             <a-row style="text-align:right;margin-bottom:20px;">
                <a-button type="primary" icon="plus" @click="handleAdd()" >新增标签</a-button>
                <a-button type="primary" icon="reload" @click="syncTag" style="margin-left:20px" :disabled="syncBtn">同步</a-button>
            </a-row>
            <a-table 
                :columns="columns" 
                :data-source="tableData"
                :row-key="record => record._id"
                :pagination="pagination"
                :loading="loading"
                @change="handleTableChange"
            >
                <a-tag slot="tag" color="green"  slot-scope="text">{{text}}</a-tag>
                <a-tag slot="group" color="blue"  slot-scope="text">{{text}}</a-tag>
                <span slot="time" slot-scope="text">{{text | parseTime('{y}-{m}-{d} {h}:{i}:{s}')}}</span>
                <template slot="action" slot-scope="text,record">
                    <div class="operation-wrapper">
                        <a-button type="link" @click="updateLabel(record)">编辑标签</a-button>
                        <a-button type="link" @click="updateLabelGroup(record)">编辑标签组</a-button>
                        <!-- <a-button type="link" style="color:red" @click="handelClickDel(record)">删除</a-button> -->
                    </div>  
                </template>
            </a-table>
        </a-card>
         <a-modal v-model="visible" title="新增标签" @ok="handleSubmit">
            <a-form  :form="addForm">
                <a-form-item 
                    label="标签组" 
                    :label-col="{span:6}" 
                    :wrapper-col="{ span: 14 }"
                    >
                    <a-select 
                        v-model='addForm.group_name' 
                        :show-search="true" 
                        :not-found-content="null" 
                        :show-arrow="false" 
                        :filter-option="true"
                        :autoClearSearchValue="false"
                        @search="handleGroupInput"
                        @change="handleChange"
                        @blur="handleBlur"
                        >
                            <a-select-option v-for=" item in groupList" :key="item.group_name"> {{item.group_name}}</a-select-option>
                        </a-select>
                </a-form-item>
                <a-form-item 
                    label="标签名" 
                    :label-col="{span:6}" 
                    :wrapper-col="{ span: 14 }"
                    >
                    <div class="add-view" v-for="(item,index) in tagList" :key="index">
                        <a-input
                            v-model="item.name"
                            :maxLength="30"
                            placeholder="标签名"
                        />
                        <div style="color:#47ABFC;padding-left:20px;width:56px;cursor:pointer" v-if="index===0" @click="handleAddInp">新增</div>
                        <div style="color:#FC313D;padding-left:20px;width:56px;cursor:pointer" v-else @click="handleDeleteInp(index)">删除</div>
                    </div>
                </a-form-item>
            </a-form>
        </a-modal>
        <a-modal v-model="labelVisible" title="修改标签" @ok="handleUpdateLabel">
             <div class="tips">tips:修改后的标签组不能和已有的标签组重名，标签也不能和同一标签组下的其他标签重名</div>
            <a-form>
                <a-form-item 
                    label="原标签名" 
                    :label-col="{span:6}" 
                    :wrapper-col="{ span: 14 }"
                    >
                        <a-input
                            v-model="updateForm.old"
                            :disabled="true"
                            placeholder="标签名"
                        />
                </a-form-item>
                <a-form-item 
                    label="新标签名" 
                    :label-col="{span:6}" 
                    :wrapper-col="{ span: 14 }"
                    >
                        <a-input
                            v-model="updateForm.newVal"
                            :maxLength="30"
                            placeholder="标签名"
                        />
                </a-form-item>
            </a-form>
        </a-modal>
        <a-modal v-model="groupVisible" title="修改标签组" @ok="handleUpdateGroup">
            <div class="tips">tips:修改后的标签组不能和已有的标签组重名，标签也不能和同一标签组下的其他标签重名</div>
            <a-form>
                <a-form-item 
                    label="原标签组名" 
                    :label-col="{span:6}" 
                    :wrapper-col="{ span: 14 }"
                    >
                        <a-input
                            v-model="updateForm.old"
                            :disabled="true"
                            placeholder="标签名"
                        />
                </a-form-item>
                <a-form-item 
                    label="新标签组名" 
                    :label-col="{span:6}" 
                    :wrapper-col="{ span: 14 }"
                    >
                        <a-input
                            v-model="updateForm.newVal"
                            :maxLength="30"
                            placeholder="标签名"
                        />
                </a-form-item>
            </a-form>
        </a-modal>
    </div>
</template>
<script>
const columns = [
    {
      title: "标签名",
      dataIndex: "label_name",
      align: "center",
      ellipsis: true,
      scopedSlots: { customRender: 'tag' },
    },
    {
      title: "标签组",
      dataIndex: "group_name",
      align: "center",
      ellipsis: true,
      scopedSlots: { customRender: 'group' },
    },
    {
      title: "创建时间",
      dataIndex: "create_time",
      align: "center",
      ellipsis: true,
      scopedSlots: { customRender: 'time' },
    },
    {
        title: "操作",
        width: 120,
        align: "center",
        ellipsis: true,
        scopedSlots: { customRender: "action" },
    },
]
import {
    mapState
  } from 'vuex'
export default {
    name:'a-label',
    data(){
        return{
            visible:false,
            labelVisible:false,
            groupVisible:false,
            loading:false,
            syncBtn:false,
            title:'',
            columns,
            searchForm:{
                group_name:''
            },
            tableData:[],
            groupList:[],
            pagination:{
                total: 0,
                current: 1,
                pageSize: 10, //每页中显示10条数据
                showTotal: (total) => `共有 ${total} 条数据`, //分页中显示总的数据
            },
            tagList:[{name:''}],
            addForm:{
                tag:[],
                group_id:'',
                group_name:''
            },
            updateForm:{

            }
        }
    },
    computed: {
        ...mapState(['mqttInit'])
    },
    watch:{
        mqttInit:{
            handler(val){
                if(val){
                    this.handleSearch()
                    this.handleSearchGroup()
                }
            },
            immediate:true
        }
    },
    methods:{
        handelClickDel(row){
            let that = this
            this.$confirm({
                title: '',
                content: '您确定要删除此标签吗？',
                okText: '确认',
                cancelText: '取消',
                onOk() {
                    that.handleDelete(row)
                },

            });
        },
        handleDelete(row){
            let payload = {
                msgId:'delTag',
                data:{
                    tag_id:[row.tag_id]
                }
            }
            this.$mqtt.doPublish(
                {
                    pubTopic: "scrm/outerTag/delTag",
                    payload,
                },
                (topic, data) => {
                    if(data.code==200){
                        this.$message.success('删除成功')
                        this.handleSearch()
                        this.handleSearchGroup()
                        this.getLabel()
                    }
                },
                this
            );
        },
        handleDeleteInp(index){
            this.tagList.splice(index,1)
        },
        handleAddInp(){
            this.tagList.push({name:''})
        },
        handleBlur(val){
            this.addForm.group_name = val;
            if(this.groupList.findIndex(item=>item.group_name == val)==-1){
                this.groupList.push({group_name:val});
            }
        },
        handleChange(val){
           this.addForm.group_name = val
        },
        handleGroupInput(val){
           this.addForm.group_name = val
        },
        syncTag(){
            this.syncBtn = true
            setTimeout(()=>{
                this.syncBtn = false
            },10000)
             let payload = {
                msgId:'syncTag',
                data:{
                }
            }
            this.$mqtt.doPublish(
                {
                pubTopic: "scrm/outerTag/syncTag",
                payload,
                },
                (topic, data) => {
                    this.handleSearch()
                    this.handleSearchGroup()
                    this.getLabel()
                },
                this
            );
        },
        handleClickSearch(){
            this.pagination.current =1
            this.handleSearch()
        },
        handleSearch(){ 
            this.loading = true  
             let payload = {
                msgId:'searchTag',
                data:{
                    group_name: this.searchForm.group_name,
                    pageSize:this.pagination.pageSize,
                    pageNum:this.pagination.current,
                    fetch:0
                }
            }
            this.$mqtt.doPublish(
                {
                pubTopic: "scrm/wxapi/searchTag",
                payload,
                },
                (topic, data) => {
                    this.loading = false
                    this.tableData = data.res
                    this.pagination.total = data.count
                },
                this
            );
        },
        getLabel(){
            let payload = {
                msgId:'searchTags',
                data:{
                    fetch:1
                }
            }
            this.$mqtt.doPublish(
                {
                pubTopic: "scrm/wxapi/searchTag",
                payload,
                },
                (topic, data) => {
                  if(data.success){
                    let tagList = data.res
                    let arr = []
                    tagList.forEach(item=>{
                        if(arr.findIndex(obj=>obj.id==item.group_id)==-1){
                            arr.push({id:item.group_id,tag_id:item.group_id,name:item.group_name,children:[]})
                        }
                    })
                    tagList.forEach(item=>{
                        let ind = arr.findIndex(obj=>obj.id==item.group_id)
                        if(ind!=-1){
                            arr[ind].children.push({id:item.label_id,name:item.label_name,tag_id:item.tag_id})
                        }
                    })
                    this.$store.commit('syncUpdateTag',{tagList:tagList,tagTree:arr})
                  }
                    
                },
                this
            );
        },
        handleSearchGroup(){
            let payload = {
                msgId:'getTagGroups',
                data:{}
            }
            this.$mqtt.doPublish(
                {
                pubTopic: "scrm/wxapi/getTagGroups",
                payload,
                },
                (topic, data) => {
                    this.groupList = data.res
                },
                this
            );
        },
        resetSearchForm(){

            this.searchForm.group_name = ""
        },
        handleAdd(){
            this.addForm = {
                tag:[],
                group_id:'',
                group_name:''
            }
            this.tagList = [{name:''}]
            this.visible = true
        },
        handleSubmit(){
            if(!this.addForm.group_name.trim()){
                this.$message.error('请填写完整,内容不能为空')
                return
            }
            if(this.tagList.findIndex(item=>item.name=='')!==-1){
                this.$message.error('请填写完整,内容不能为空')
                return
            }
            if(this.addForm.group_name.length>30){
                this.$message.error('最大长度30个字符')
                return
            }
            let obj = {
                group_name:this.addForm.group_name,
                tag:this.tagList
            }
            let selectObj = this.groupList.find(item=>item.group_name == this.addForm.group_name)
            if(selectObj.group_id!==""){
                obj.group_id = this.addForm.group_id
            }
            let payload = {
                msgId:'addTag',
                data:obj
            }
            this.$mqtt.doPublish(
                {
                pubTopic: "scrm/outerTag/addTag",
                payload,
                },
                (topic, data) => {
                    if(data.code==200){
                        this.visible = false
                        this.$message.success('新增成功')
                        this.handleSearch()
                        this.handleSearchGroup()
                        this.getLabel()
                    }else if(data.code==500){
                        this.$message.error(data.wxMessage || '新增失败')
                    }
                },
                this
            );
        },
        handleTableChange(pagination){
            this.pagination = pagination
            this.handleSearch()
        },
        showDetail(){

        },
        updateLabel(item){
            this.updateForm.id = item.tag_id
            this.updateForm.old = item.label_name
            this.$set(this.updateForm,"newVal",item.label_name)
            this.labelVisible = true
        },
        updateLabelGroup(item){
            this.updateForm.id = item.group_id
            this.updateForm.old = item.group_name
            this.$set(this.updateForm,"newVal",item.group_name)
            this.groupVisible = true
        },
        handleUpdateGroup(){
            this.groupVisible = false
            if(!this.updateForm.newVal){
                this.$message.error('内容不允许为空')
                return
            }
            let obj = {
                group_id:this.updateForm.id,
                group_name:this.updateForm.newVal
            }
             let payload = {
                msgId:'updateGroup',
                data:obj
            }
            this.$mqtt.doPublish(
                {
                pubTopic: "scrm/outerTag/editTag",
                payload,
                },
                (topic, data) => {
                    if(data.code==200){
                        this.$message.success('修改成功')
                        this.handleSearch()
                        this.handleSearchGroup()
                        this.getLabel()
                    }else if(data.code==500){
                        this.$message.error(data.wxMessage || '修改失败')
                    }
                },
                this
            );
        },
        handleUpdateLabel(){
            this.labelVisible  = false
            if(!this.updateForm.newVal){
                this.$message.error('内容不允许为空')
                return
            }
            let obj = {
                tag_id:this.updateForm.id,
                label_name:this.updateForm.newVal
            }
            let payload = {
                msgId:'updateLabel',
                data:obj
            }
            this.$mqtt.doPublish(
                {
                pubTopic: "scrm/outerTag/editTag",
                payload,
                },
                (topic, data) => {
                    if(data.code==200){
                        this.$message.success('修改成功')
                        this.handleSearch()
                        this.handleSearchGroup()
                        this.getLabel()
                    }else if(data.code==500){
                        this.$message.error(data.wxMessage || '修改失败')
                    }
                },
                this
            );
        }

    }
}
</script>
<style lang="scss" scoped>
.operation-wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.add-view{
    display: flex;
    align-items: center;
}
.tips{
    font-size: 12px;
    color: chocolate;
    padding:10px 30px;
}
</style>